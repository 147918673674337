import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Greetings from Harvard where The Office’s BJ Novak just announced that PayPerks won the Silver in the ‘Best Newcomer in Payments’ category. (Bet you never thought you would see all those things in one sentence)`}</p>
    <p>{`When we were named a finalist a few weeks ago, we were humbled to be among such esteemed company; so to be named a winner was really the icing on the cake.`}</p>
    <p>{`We’d be remiss without congratulating Stripe on the Gold. We’ll see you next year ;)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "170.66666666666669%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAiABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFgEAAwAAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHDn23CVE1yyhroUKvYqObgP//EAB8QAAEDBAMBAAAAAAAAAAAAAAIBAwQAEhMzERQhI//aAAgBAQABBQJy4pAoZJHX5meOS86FsfW9vNfI+t3nsEREjHgE2F2MKQBr/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAg/9oACAEDAQE/AWOP/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8BK//EACMQAAIBAgUFAQAAAAAAAAAAAAECABCRERIiMkEDITFCcaH/2gAIAQEABj8CZVJ5jHM2mjMODMvT9+5o32DT+UY4HzMCDah0LabFtNotP//EACAQAQACAQMFAQAAAAAAAAAAAAEAESExUWEQQYGhsfH/2gAIAQEAAT8hJhNqhhIoFtsRsq5jd1H2Exddc+82d5Y8wmhHOEfvKBJT0OenD3pcNxCKN7J+YhTAx//aAAwDAQACAAMAAAAQFPgMiA//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEg/9oACAEDAQE/EE3Ax//EABgRAQADAQAAAAAAAAAAAAAAAAEQESAx/9oACAECAQE/EAVA8x//xAAfEAEAAgIBBQEAAAAAAAAAAAABABEhMUFRYXGRsfD/2gAIAQEAAT8QXvCJLpcQHa6qV23uW9l8qvBBotbXU3PUr5mUrorT0bNeY8fP4R87iDfTDZIvJUw4zMXPL4QEYot1JeK6K7wDNPfvLM0Hh8ETsEqsr6j+O+R2GXoBP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/23d8fa428ebae8bfd525984b60570632/f3a60/PYMNTS.webp 375w"],
            "sizes": "(max-width: 375px) 100vw, 375px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/23d8fa428ebae8bfd525984b60570632/bf173/PYMNTS.jpg 375w"],
            "sizes": "(max-width: 375px) 100vw, 375px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/23d8fa428ebae8bfd525984b60570632/bf173/PYMNTS.jpg",
            "alt": "PYMNTS",
            "title": "PYMNTS",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      